<template>
  <div id="app">
    <Button label="Create new race" @click="creating_race = !creating_race" />
    <CreateGame :creating_race="creating_race" :db_names="databases[0]" />
    <Dialog header="Delete" v-model:visible="delete_db_modal_display">
      Are you sure you want to delete {{ current_db }}
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="delete_db_modal_display = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          autofocus
          @click="delete_db_part2()"
        />
      </template>
    </Dialog>
    <div v-for="db in databases[0]" :key="db.name">
      <router-link :to="'/r/' + db.name">
        <h2>Race: {{ db.name }}</h2>
      </router-link>
      <Button
        style="display: inline-block"
        label="Delete"
        class="p-button-danger"
        @click="delete_db_part_1(db.name)"
      />
    </div>
  </div>
</template>

<script>
import CreateGame from "../components/CreateGame.vue";
import { RaceService } from "../service/race_service.js";
import { connection } from "../service/jsstore_con";
import { initJsStore } from "../service/idb_service";
import { getDatabase } from "../service/idb_service";
export default {
  name: "app",
  components: {
    CreateGame,
  },
  data() {
    return {
      race_service: new RaceService(),
      databases: [],
      creating_race: false,
      delete_db_modal_display: false,
      current_db: "",
    };
  },
  mounted() {
    this.get_dbs()
  },
  computed: {
    races() {
      return this.race_service.getRaces();
    },
  },
  methods: {
    get_dbs(){
      this.databases = []
      try {
        indexedDB.databases().then((r) => this.databases.push(r));
      } catch (ex) {
        console.log(ex);
        this.databases.push(["cannot load databases"]);
      }
    },
    delete_db_part_1(db_name) {
      this.current_db = db_name;
      this.delete_db_modal_display = true;
    },
    async delete_db_part2() {
      const dataBase = getDatabase(this.current_db);
      await connection.initDb(dataBase);
      console.log(connection);
      let t = this
      connection
        .dropDb()
        .then(function () {
          console.log("Db deleted successfully");
          t.delete_db_modal_display = false;
          t.get_dbs();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
