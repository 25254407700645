import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker';
import router from './router';
// import store from './store';
// import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import Panel from 'primevue/panel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabMenu from 'primevue/tabmenu';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import ScrollPanel from 'primevue/scrollpanel';
import TieredMenu from 'primevue/tieredmenu';
import Divider from 'primevue/divider';
import 'primeflex/primeflex.css';
import "primevue/resources/themes/arya-purple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css"

const app = createApp(App).use(router).use(PrimeVue, { ripple: true }) //.use(VueAxios) use(store).
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("Button", Button);
app.component("Panel", Panel);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("TabMenu", TabMenu);
app.component("Dropdown", Dropdown);
app.component("Dialog", Dialog);
app.component("TabPanel", TabPanel);
app.component("TabView", TabView);
app.component("Chart", Chart);
app.component("Slider", Slider);
app.component("ScrollPanel", ScrollPanel);
app.component("Divider", Divider);
app.component("TieredMenu", TieredMenu);
app.directive('tooltip', Tooltip);

app.mount('#app')
