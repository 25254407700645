<template>
  <div class="race">
    <h1>Politics</h1>
    {{ $route.params.id }}
    <p v-for="candidate in candidates" :key="candidate">{{ candidate }}</p>
  </div>
</template>

<script>
import { RaceService } from '../service/race_service';
import { CandidateService } from '../service/candidate_service';
import { PolicyService } from '../service/policy_service';
export default {
  name: "app",
  components: {
  },
  data() {
    return {
      race_id: this.$route.params.id,
      candidates: [],
      policy_service: new PolicyService(),
    };
  },
  mounted() {
      this.refreshCandidates()
  },
  methods: {
    async refreshCandidates() {
      this.candidates = await new CandidateService.getCandidates();
    },
  },
  computed: {
    races() {
      return false
    },
  },
}
</script>
