import { connection } from "./jsstore_con";

export class PolicyService {

    constructor() {

        this.tableName = "Policies";
    }

    getPolicies() {
        return connection.select({
            from: this.tableName,
        })
    }

    addPolicy(Policy) {
        return connection.insert({
            into: this.tableName,
            values: [Policy],
            return: true
        })
    }

    getPolicyById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    removePolicy(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    updatePolicyById(Policy) {
        return connection.update({
            in: this.tableName,
            set: {
                candidate_id: Policy.candidate_id,
                policy_name: Policy.policy_name,
                policy_rating: Policy.policy_rating,
                policy_type_compass: Policy.policy_type_compass,
                tooltip_help: Policy.tooltip_help,
            },
            where: {
                id: Policy.id
            }
        })
    }
}