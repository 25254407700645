const states = [{ id: 1, name: 'Alabama', electoral_college: 9, population: 5024279 },
{ id: 2, name: 'Alaska', electoral_college: 3, population: 733391 },
{ id: 3, name: 'Arizona', electoral_college: 11, population: 7151502 },
{ id: 4, name: 'Arkansas', electoral_college: 6, population: 3011524 },
{ id: 5, name: 'California', electoral_college: 55, population: 39538223 },
{ id: 6, name: 'Colorado', electoral_college: 9, population: 5773714 },
{ id: 7, name: 'Connecticut', electoral_college: 7, population: 3605944 },
{ id: 8, name: 'Delaware', electoral_college: 3, population: 989948 },
{ id: 9, name: 'District of Columbia', electoral_college: 3, population: 689545 },
{ id: 10, name: 'Florida', electoral_college: 29, population: 21538187 },
{ id: 11, name: 'Georgia', electoral_college: 16, population: 10711908 },
{ id: 12, name: 'Hawaii', electoral_college: 4, population: 1455271 },
{ id: 13, name: 'Idaho', electoral_college: 4, population: 1839106 },
{ id: 14, name: 'Illinois', electoral_college: 20, population: 12812508 },
{ id: 15, name: 'Indiana', electoral_college: 11, population: 6785528 },
{ id: 16, name: 'Iowa', electoral_college: 6, population: 3190369 },
{ id: 17, name: 'Kansas', electoral_college: 6, population: 2937880 },
{ id: 18, name: 'Kentucky', electoral_college: 8, population: 4505836 },
{ id: 19, name: 'Louisiana', electoral_college: 8, population: 4657757 },
{ id: 20, name: 'Maine', electoral_college: 4, population: 1362359 },
{ id: 21, name: 'Maryland', electoral_college: 10, population: 6177224 },
{ id: 22, name: 'Massachusetts', electoral_college: 11, population: 7029917 },
{ id: 23, name: 'Michigan', electoral_college: 16, population: 10077331 },
{ id: 24, name: 'Minnesota', electoral_college: 10, population: 5706494 },
{ id: 25, name: 'Mississippi', electoral_college: 6, population: 2961279 },
{ id: 26, name: 'Missouri', electoral_college: 10, population: 6154913 },
{ id: 27, name: 'Montana', electoral_college: 3, population: 1084225 },
{ id: 28, name: 'Nebraska', electoral_college: 5, population: 1961504 },
{ id: 29, name: 'Nevada', electoral_college: 6, population: 3104614 },
{ id: 30, name: 'New Hampshire', electoral_college: 4, population: 1377529 },
{ id: 31, name: 'New Jersey', electoral_college: 14, population: 9288994 },
{ id: 32, name: 'New Mexico', electoral_college: 5, population: 2117522 },
{ id: 33, name: 'New York', electoral_college: 29, population: 20201249 },
{ id: 34, name: 'North Carolina', electoral_college: 15, population: 10439388 },
{ id: 35, name: 'North Dakota', electoral_college: 3, population: 779094 },
{ id: 36, name: 'Ohio', electoral_college: 18, population: 11799448 },
{ id: 37, name: 'Oklahoma', electoral_college: 7, population: 3959353 },
{ id: 38, name: 'Oregon', electoral_college: 7, population: 4237256 },
{ id: 39, name: 'Pennsylvania', electoral_college: 20, population: 13011844 },
{ id: 40, name: 'Rhode Island', electoral_college: 4, population: 1097379 },
{ id: 41, name: 'South Carolina', electoral_college: 9, population: 5118425 },
{ id: 42, name: 'South Dakota', electoral_college: 3, population: 886667 },
{ id: 43, name: 'Tennessee', electoral_college: 11, population: 6910840 },
{ id: 44, name: 'Texas', electoral_college: 38, population: 29145505 },
{ id: 45, name: 'Utah', electoral_college: 6, population: 3271616 },
{ id: 46, name: 'Vermont', electoral_college: 3, population: 643077 },
{ id: 47, name: 'Virginia', electoral_college: 13, population: 8631393 },
{ id: 48, name: 'Washington', electoral_college: 12, population: 7705281 },
{ id: 49, name: 'West Virginia', electoral_college: 5, population: 1793716 },
{ id: 50, name: 'Wisconsin', electoral_college: 10, population: 5893718 },
{ id: 51, name: 'Wyoming', electoral_college: 3, population: 576851 },]

export default states