<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <img :src="avatar_source" />
    </div>
    <div class="p-col-12 p-md-6">
      <h3>Avatar Setup</h3>
      <Button label="Random Avatar" @click="randomAvatar()" />
      <span class="p-float-label">
        <Dropdown
          v-model="skin_color"
          :options="skin_colors"
          optionLabel="name"
          id="skin_colors"
        />
        <label for="skin_colors">Skin Color</label>
      </span>
      <span class="p-float-label">
        <Dropdown
          v-model="hair_style"
          :options="hair_styles"
          optionLabel="name"
          id="hair_styles"
        />
        <label for="hair_styles">Hair Style</label>
      </span>
      <span
        class="p-float-label"
        v-if="
          !['NoHair', 'Eyepatch', 'Hijab', 'Turban'].includes(hair_style.value)
        "
      >
        <Dropdown
          v-model="hair_color"
          :options="hair_colors"
          optionLabel="name"
          id="hair_colors"
        />
        <label for="hair_colors">Hair Color</label>
      </span>
      <span class="p-float-label">
        <Dropdown
          v-model="clothing_type"
          :options="clothing_types"
          optionLabel="name"
          id="clothing_types"
        />
        <label for="clothing_types">Clothing Type</label>
      </span>
      <span
        class="p-float-label"
        v-if="!['BlazerShirt', 'BlazerSweater'].includes(clothing_type.value)"
      >
        <Dropdown
          v-model="clothing_color"
          :options="clothing_colors"
          optionLabel="name"
          id="clothing_colors"
        />
        <label for="clothing_colors">Clothing Color</label>
      </span>
      <span class="p-float-label">
        <Dropdown
          v-model="eye_type"
          :options="eye_types"
          optionLabel="name"
          id="eye_types"
        />
        <label for="eye_types">Eye Type</label>
      </span>
      <span class="p-float-label">
        <Dropdown
          v-model="eyebrow_type"
          :options="eyebrow_types"
          optionLabel="name"
          id="eyebrow_types"
        />
        <label for="eyebrow_types">Eyebrow Type</label>
      </span>
      <span class="p-float-label">
        <Dropdown
          v-model="mouth_type"
          :options="mouth_types"
          optionLabel="name"
          id="mouth_types"
        />
        <label for="mouth_types">Mouth Type</label>
      </span>

      <span class="p-float-label">
        <Dropdown
          v-model="facial_hair_type"
          :options="facial_hair_types"
          optionLabel="name"
          id="facial_hair_types"
        />
        <label for="facial_hair_types">Facial Hair Type</label>
      </span>
      <span
        class="p-float-label"
        v-if="!['Blank'].includes(facial_hair_type.value)"
      >
        <Dropdown
          v-model="facial_hair_color"
          :options="facial_hair_colors"
          optionLabel="name"
          id="facial_hair_colors"
        />
        <label for="facial_hair_colors">Facial Hair Color</label>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CandidateAvatar",
  props: {
    candidate_preset: Object,
  },
  data() {
    return {
      skin_color: "",
      skin_colors: [
        { value: "Pale", name: "Pale" },
        { value: "Light", name: "Light" },
        { value: "Tanned", name: "Tanned" },
        { value: "Brown", name: "Brown" },
        { value: "DarkBrown", name: "Dark Brown" },
        { value: "Black", name: "Black" },
      ],
      hair_color: "",
      hair_colors: [
        { value: "Auburn", name: "Auburn" },
        { value: "Black", name: "Black" },
        { value: "Blonde", name: "Blonde" },
        { value: "BlondeGolden", name: "Golden Blonde" },
        { value: "Brown", name: "Brown" },
        { value: "BrownDark", name: "Dark Brown" },
        { value: "PastelPink", name: "Pink" },
        { value: "Platinum", name: "Platinum" },
        { value: "Red", name: "Red" },
        { value: "SilverGrey", name: "Silver Gray" },
      ],
      hair_style: "",
      hair_styles: [
        { value: "NoHair", name: "No hair" },
        { value: "Eyepatch", name: "Eyepatch" },
        { value: "Hijab", name: "Hijab" },
        { value: "Turban", name: "Turban" },
        { value: "LongHairBob", name: "Bob" },
        { value: "LongHairBun", name: "Bun" },
        { value: "ShortHairTheCaesar", name: "Caesar" },
        { value: "ShortHairTheCaesarSidePart", name: "Caesar with Side Part" },
        { value: "LongHairCurvy", name: "Curvy" },
        { value: "ShortHairShortFlat", name: "Flat" },
        { value: "ShortHairFrizzle", name: "Frizzle" },
        { value: "LongHairFro", name: "Fro" },
        { value: "LongHairFroBand", name: "Fro with Band" },
        { value: "LongHairBigHair", name: "Long Big Hair" },
        { value: "LongHairCurly", name: "Long Curly" },
        { value: "LongHairDreads", name: "Long Dreads" },
        { value: "LongHairMiaWallace", name: "Mia Wallace" },
        { value: "LongHairNotTooLong", name: "Medium" },
        { value: "ShortHairShortRound", name: "Round" },
        { value: "LongHairShavedSides", name: "Shaved Sides" },
        { value: "ShortHairDreads01", name: "Short Dreads 1" },
        { value: "ShortHairDreads02", name: "Short Dreads 2" },
        { value: "ShortHairSides", name: "Sides" },
        { value: "LongHairStraight", name: "Straight 1" },
        { value: "LongHairStraight2", name: "Straight 2" },
        { value: "LongHairStraightStrand", name: "Straight 3" },
        { value: "ShortHairShaggyMullet", name: "Shaggy Mullet" },
        { value: "ShortHairShortCurly", name: "Short Curly" },
      ],
      clothing_type: "",
      clothing_types: [
        { value: "BlazerShirt", name: "Blazer Shirt" },
        { value: "BlazerSweater", name: "Blazer Sweater" },
        { value: "CollarSweater", name: "Collar Sweater" },
        { value: "GraphicShirt", name: "Graphic Shirt" },
        { value: "Hoodie", name: "Hoodie" },
        { value: "Overall", name: "Overall" },
        { value: "ShirtCrewNeck", name: "Shirt Crew Neck" },
        { value: "ShirtScoopNeck", name: "Shirt Scoop Neck" },
        { value: "ShirtVNeck", name: "Shirt V Neck" },
      ],
      clothing_color: "",
      clothing_colors: [
        { value: "Black", name: "Black" },
        { value: "Blue03", name: "Dark Blue" },
        { value: "Gray02", name: "Dark Gray" },
        { value: "Blue01", name: "Light Blue" },
        { value: "Gray01", name: "Light Gray" },
        { value: "Blue02", name: "Medium Blue" },
        { value: "PastelBlue", name: "Pastel Blue" },
        { value: "PastelGreen", name: "Pastel Green" },
        { value: "PastelOrange", name: "Pastel Orange" },
        { value: "PastelRed", name: "Pastel Red" },
        { value: "PastelYellow", name: "Pastel Yellow" },
        { value: "Pink", name: "Pink" },
        { value: "Red", name: "Red" },
        { value: "White", name: "White" },
      ],
      eye_type: "",
      eye_types: [
        { value: "Close", name: "Closed" },
        { value: "Cry", name: "Crying" },
        { value: "Default", name: "Default" },
        { value: "Dizzy", name: "Dizzy" },
        { value: "EyeRoll", name: "Eye Roll" },
        { value: "Happy", name: "Happy" },
        { value: "Hearts", name: "Hearts" },
        { value: "Side", name: "Side" },
        { value: "Squint", name: "Squint" },
        { value: "Surprised", name: "Surprised" },
        { value: "WinkWacky", name: "Wacky Wink" },
        { value: "Wink", name: "Wink" },
      ],
      eyebrow_type: "",
      eyebrow_types: [
        { value: "Angry", name: "Angry" },
        { value: "AngryNatural", name: "Angry Natural" },
        { value: "Default", name: "Default" },
        { value: "DefaultNatural", name: "Default Natural" },
        { value: "FlatNatural", name: "Flat Natural" },
        { value: "RaisedExcited", name: "Raised Excited" },
        { value: "RaisedExcitedNatural", name: "Raised Excited Natural" },
        { value: "SadConcerned", name: "Sad Concerned" },
        { value: "SadConcernedNatural", name: "Sad Concerned Natural" },
        { value: "UnibrowNatural", name: "Unibrow Natural" },
        { value: "UpDown", name: "Up Down" },
        { value: "UpDownNatural", name: "Up Down Natural" },
      ],
      mouth_type: "",
      mouth_types: [
        { value: "Concerned", name: "Concerned" },
        { value: "Default", name: "Default" },
        { value: "Disbelief", name: "Disbelief" },
        { value: "Eating", name: "Eating" },
        { value: "Grimace", name: "Grimace" },
        { value: "Sad", name: "Sad" },
        { value: "ScreamOpen", name: "Scream" },
        { value: "Serious", name: "Serious" },
        { value: "Smile", name: "Smile" },
        { value: "Tounge", name: "Tounge" },
        { value: "Twinkle", name: "Twinkle" },
        // { value: "Vomit", name: "Vomit" },
      ],
      facial_hair_type: "",
      facial_hair_types: [
        { value: "Blank", name: "No Facial Hair" },
        { value: "MoustacheFancy", name: "Fancy Mustache" },
        { value: "BeardLight", name: "Light Beard" },
        { value: "BeardMajestic", name: "Majestic Beard" },
        { value: "MoustacheMagnum", name: "Magnum Mustache" },
        { value: "BeardMedium", name: "Medium Beard" },
      ],
      facial_hair_color: "",
      facial_hair_colors: [
        { value: "Auburn", name: "Auburn" },
        { value: "Black", name: "Black" },
        { value: "Blonde", name: "Blonde" },
        { value: "BlondeGolden", name: "Golden Blonde" },
        { value: "Brown", name: "Brown" },
        { value: "BrownDark", name: "Dark Brown" },
        { value: "Platinum", name: "Platinum" },
        { value: "Red", name: "Red" },
      ],
    };
  },
  computed: {
    avatar_source() {
      let url = "https://avataaars.io/?avatarStyle=Circle";
      url = url + "&skinColor=" + this.skin_color.value;
      url = url + "&topType=" + this.hair_style.value;
      url = url + "&hairColor=" + this.hair_color.value;
      url = url + "&clotheType=" + this.clothing_type.value;
      url = url + "&clotheColor=" + this.clothing_color.value;
      url = url + "&eyeType=" + this.eye_type.value;
      url = url + "&eyebrowType=" + this.eyebrow_type.value;
      url = url + "&mouthType=" + this.mouth_type.value;
      url = url + "&facialHairType=" + this.facial_hair_type.value;
      url = url + "&facialHairColor=" + this.facial_hair_color.value;
      this.$emit("avatar_source_url", url);
      return url;
      //avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light"
    },
  },
  watch: {
    candidate_preset() {
      this.skin_color = this.skin_colors.find(
        (x) => x.name === this.candidate_preset.skin_color
      );
      this.hair_color = this.hair_colors.find(
        (x) => x.name === this.candidate_preset.hair_color
      );
      this.hair_style = this.hair_styles.find(
        (x) => x.name === this.candidate_preset.hair_style
      );
      this.clothing_type = this.clothing_types.find(
        (x) => x.name === this.candidate_preset.clothing_type
      );
      this.clothing_color = this.clothing_colors.find(
        (x) => x.name === this.candidate_preset.clothing_color
      );
      this.eye_type = this.eye_types.find(
        (x) => x.name === this.candidate_preset.eye_type
      );
      this.eyebrow_type = this.eyebrow_types.find(
        (x) => x.name === this.candidate_preset.eyebrow_type
      );
      this.mouth_type = this.mouth_types.find(
        (x) => x.name === this.candidate_preset.mouth_type
      );
      this.facial_hair_color = this.facial_hair_colors.find(
        (x) => x.name === this.candidate_preset.facial_hair_color
      );
      this.facial_hair_type = this.facial_hair_types.find(
        (x) => x.name === this.candidate_preset.facial_hair_type
      );
    },
  },
  methods: {
    randomAvatar() {
      this.skin_color =
        this.skin_colors[Math.floor(Math.random() * this.skin_colors.length)];
      this.hair_style =
        this.hair_styles[Math.floor(Math.random() * this.hair_styles.length)];
      this.hair_color =
        this.hair_colors[Math.floor(Math.random() * this.hair_colors.length)];
      this.clothing_type =
        this.clothing_types[
          Math.floor(Math.random() * this.clothing_types.length)
        ];
      this.clothing_color =
        this.clothing_colors[
          Math.floor(Math.random() * this.clothing_colors.length)
        ];
      this.eye_type =
        this.eye_types[Math.floor(Math.random() * this.eye_types.length)];
      this.eyebrow_type =
        this.eyebrow_types[
          Math.floor(Math.random() * this.eyebrow_types.length)
        ];
      this.mouth_type =
        this.mouth_types[Math.floor(Math.random() * this.mouth_types.length)];
      this.facial_hair_type =
        this.facial_hair_types[
          Math.floor(Math.random() * this.facial_hair_types.length)
        ];
      this.facial_hair_color =
        this.facial_hair_colors[
          Math.floor(Math.random() * this.facial_hair_colors.length)
        ];
    },
    getRandomURL() {
      let url = "https://avataaars.io/?avatarStyle=Circle";
      url =
        url +
        "&skinColor=" +
        this.skin_colors[Math.floor(Math.random() * this.skin_colors.length)]
          .value;
      url =
        url +
        "&topType=" +
        this.hair_styles[Math.floor(Math.random() * this.hair_styles.length)]
          .value;
      url =
        url +
        "&hairColor=" +
        this.hair_colors[Math.floor(Math.random() * this.hair_colors.length)]
          .value;
      url =
        url +
        "&clotheType=" +
        this.clothing_types[
          Math.floor(Math.random() * this.clothing_types.length)
        ].value;
      url =
        url +
        "&clotheColor=" +
        this.clothing_colors[
          Math.floor(Math.random() * this.clothing_colors.length)
        ].value;
      url =
        url +
        "&eyeType=" +
        this.eye_types[Math.floor(Math.random() * this.eye_types.length)].value;
      url =
        url +
        "&eyebrowType=" +
        this.eyebrow_types[
          Math.floor(Math.random() * this.eyebrow_types.length)
        ].value;
      url =
        url +
        "&mouthType=" +
        this.mouth_types[Math.floor(Math.random() * this.mouth_types.length)]
          .value;
      url =
        url +
        "&facialHairType=" +
        this.facial_hair_types[
          Math.floor(Math.random() * this.facial_hair_types.length)
        ].value;
      url =
        url +
        "&facialHairColor=" +
        this.facial_hair_colors[
          Math.floor(Math.random() * this.facial_hair_colors.length)
        ].value;
      return url;
    },
  },
};
</script>