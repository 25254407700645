import { connection } from "./jsstore_con";

export class RaceService {

    constructor() {

        this.tableName = "Races";
    }

    getRaces() {
        return connection.select({
            from: this.tableName,
        })
    }

    addRace(Race) {
        return connection.insert({
            into: this.tableName,
            values: [Race],
            return: true
        })
    }

    getRaceById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    removeRace(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    updateRaceById(Race) {
        return connection.update({
            in: this.tableName,
            set: {
                name: Race.race_name,
                amount_of_primary_competition: Race.amount_of_primary_competition,
                randomized_primary_order: Race.randomized_primary_order,
                candidate_id: Race.candidate_id,
                difficulty_rating: Race.difficulty_rating,
            },
            where: {
                id: Race.id
            }
        })
    }
}