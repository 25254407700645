<template>
  <div id="all">
    <div id="nav">
      <TabMenu :model="items" />
    </div>
    <Panel>
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </Panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { label: "Home", to: "/" },
        { label: "About", to: "/about" },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

a {
  font-weight: bold;
  color: #5f86ad;
}

a.router-link-exact-active {
  color: #42b983;
}

#all {
  background: #2c3e50;
  min-height: 100%;
  height: 100%;
  margin: 0;
}

p {
  color: whitesmoke
}

h3 {
  color: whitesmoke
}

v-cloak {
  display: none
}
</style>
