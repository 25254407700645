<template>
  <Dialog
    header="Create Race"
    v-model:visible="creating_race"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '60vw' }"
  >
    <TabView>
      <TabPanel header="Race Setup">
        <h3>Race Setup</h3>
        <span class="p-float-label">
          <InputText id="race_name" type="text" v-model="race_name" />
          <p id="includes_error" v-if="includes_error">This race cannot have the same name as another race</p>
          <label for="race_name">Race Name</label>
        </span>
        <span class="p-float-label">
          <InputNumber
            id="amount_of_primary_competition"
            v-model="amount_of_primary_competition"
          />
          <label for="amount_of_primary_competition"
            >Amount of Primary Competition</label
          >
        </span>
        <span class="p-float-label">
          <h5>Difficulty: {{ difficulty_word }}</h5>
          <Slider v-model="difficulty_rating" :step="1" :min="1" :max="5" />
        </span>
        <span>
          <label for="randomized_primary_order">Randomized Primary Order</label>
          <InputSwitch
            id="randomized_primary_order"
            v-model="randomized_primary_order"
          />
        </span>
      </TabPanel>
      <TabPanel header="Candidate Setup">
        <h3>Candidate Setup</h3>
        <span class="p-float-label">
          <InputText
            id="candidate_first_name"
            type="text"
            v-model="candidate_first_name"
          />
          <label for="candidate_first_name">First Name</label>
        </span>
        <span class="p-float-label">
          <InputText
            id="candidate_last_name"
            type="text"
            v-model="candidate_last_name"
          />
          <label for="candidate_last_name">Last Name</label>
        </span>
        <span class="p-float-label">
          <InputNumber
            id="candidate_age"
            v-model="candidate_age"
            :min="35"
            :max="120"
          />
          <label for="candidate_age">Age</label>
        </span>
        <span class="p-float-label">
          <Dropdown
            v-model="previous_position"
            :options="positions"
            optionLabel="name"
            id="previous_position"
          />
          <label for="previous_position">Previous Position</label>
        </span>
        <span class="p-float-label">
          <Dropdown
            v-model="state"
            :options="states"
            optionLabel="name"
            id="state"
          />
          <label for="state">Primary State</label>
        </span>
        <span class="p-float-label">
          <Dropdown
            v-model="race"
            :options="races"
            optionLabel="name"
            id="races"
          />
          <label for="races">Race</label>
        </span>
        <span class="p-float-label">
          <Dropdown
            v-model="party"
            :options="parties"
            optionLabel="name"
            id="parties"
          />
          <label for="parties">Party</label>
        </span>
        <span class="p-float-label">
          <Dropdown
            v-model="religion"
            :options="religions"
            optionLabel="name"
            id="religions"
          />
          <label for="religions">Religion</label>
        </span>
      </TabPanel>
      <TabPanel header="Avatar Setup">
        <CandidateAvatar
          :candidate_preset="candidate_preset"
          @avatar_source_url="setAvatarSource"
          ref="CandidateAvatar"
        ></CandidateAvatar>
      </TabPanel>
      <TabPanel header="Policy Setup">
        <PoliticalCompass
          @policies="setPolicies"
        ></PoliticalCompass>
      </TabPanel>
      <TabPanel header="Attributes Setup">
        <CandidateAttributes
          @attribute_values="attributeValues"
        ></CandidateAttributes>
      </TabPanel>
    </TabView>
    <template #footer>
      <span class="p-float-label">
        <Dropdown
          v-model="candidate_preset"
          :options="candidate_presets"
          optionLabel="name"
          id="candidate_presets"
        />
        <label for="candidate_presets">Candidate Presets</label>
      </span>
      <Button
        label="Create Race"
        icon="pi pi-check"
        @click="create_race()"
        autofocus
        :disabled="disabled"
      />
    </template>
  </Dialog>
</template>

<script>
import { initJsStore } from "../service/idb_service";
import { CandidateService } from "../service/candidate_service.js";
import { RaceService } from "../service/race_service.js";
import { PolicyService } from "../service/policy_service.js";
import CandidateAvatar from "./CandidateAvatar";
import CandidateAttributes from "./CandidateAttributes";
import PoliticalCompass from "./PoliticalCompass";
import { Global } from "../global";
import first_names from "./RandomNames";
import last_names from "./RandomNames";
import states from './States';
import candidate_presets from './CandidatePresets'
export default {
  name: "CreateGame",
  components: {
    CandidateAvatar,
    PoliticalCompass,
    CandidateAttributes,
  },
  props: {
    creating_race: Boolean,
    db_names: Array,
  },
  data() {
    return {
      candidate_service: new CandidateService(),
      race_service: new RaceService(),
      policy_service: new PolicyService(),
      race_name: "",
      difficulty_rating: 3,
      avatar_source_url: "",
      includes_error: false,
      states: states,
      policies: [],
      candidate_presets: candidate_presets,
      candidate_preset: {},
      amount_of_primary_competition: 5,
      randomized_primary_order: false,
      candidate_first_name: "",
      candidate_last_name: "",
      candidate_age: 35,
      previous_position: "",
      attribute_values: '',
      positions: [
        { name: "Vice President" },
        { name: "Governor" },
        { name: "Senator" },
        { name: "Representative" },
        { name: "CEO" },
        { name: "Mayor" },
        { name: "Author" },
        { name: "Military" },
        { name: "Cabinet Member" },
        { name: "Celebrity" },
      ],
      party: "",
      parties: [
        { name: "Democrat", popularity: 51.2 },
        { name: "Republican", popularity: 46.8 },
        { name: "Independent", popularity: 2 },
      ],
      state: "",
      race: "",
      races: [
        { name: "White", popularity: 60.1 },
        { name: "Hispanic or Latino", popularity: 18.5 },
        { name: "Black or African American", popularity: 13.4 },
        { name: "Asian", popularity: 5.9 },
        { name: "Two or more races", popularity: 2.8 },
        { name: "American Indian and Alaska Native", popularity: 1.3 },
        { name: "Native Hawaiian and Other Pacific Islander", popularity: 0.2 },
      ],
      religion: "",
      religions: [
        { name: "Evangelical Protestant", popularity: 25.4 },
        { name: "Catholic", popularity: 20.8 },
        { name: "Nothing in Particular", popularity: 15.8 },
        { name: "Mainline Protestant", popularity: 14.7 },
        { name: "Historically Black Protestant", popularity: 6.5 },
        { name: "Agnostic", popularity: 4 },
        { name: "Atheist", popularity: 3.1 },
        { name: "Jewish", popularity: 1.9 },
        { name: "Mormon", popularity: 1.6 },
        { name: "Muslim", popularity: 0.9 },
        { name: "Jehovahs Witness", popularity: 0.8 },
        { name: "Buddhist", popularity: 0.7 },
        { name: "Hindu", popularity: 0.7 },
        { name: "Orthodox Christian", popularity: 0.5 },
        { name: "Other", popularity: 2.9 },
      ],
      first_names: first_names["first_names"],
      last_names: last_names["last_names"],
    };
  },
  watch: {
    candidate_preset() {
      this.previous_position = this.positions.find(
        (x) => x.name === this.candidate_preset.previous_position
      );
      this.party = this.parties.find(
        (x) => x.name === this.candidate_preset.party
      );
      this.race = this.races.find((x) => x.name === this.candidate_preset.race);
      this.state = this.states.find((x) => x.id === this.candidate_preset.state);
      this.religion = this.religions.find(
        (x) => x.name === this.candidate_preset.religion
      );
      this.candidate_first_name = this.candidate_preset.candidate_first_name;
      this.candidate_last_name = this.candidate_preset.candidate_last_name;
      this.candidate_age = this.candidate_preset.candidate_age;
    },
  },
  computed: {
    disabled() {
      let db_names = []
      for (let name of this.db_names) {
        db_names.push(name.name)
      }
      if (db_names.includes(this.race_name)) {
        this.includes_error = true;
        return true;
      } else {
        this.includes_error = false;
        if (
          this.race_name != "" &&
          this.candidate_first_name != "" &&
          this.candidate_last_name != "" &&
          this.previous_position != "" &&
          this.race != "" &&
          this.parties != "" &&
          this.religion != ""
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    difficulty_word() {
      if (this.difficulty_rating === 1) {
        return "Easiest";
      } else if (this.difficulty_rating === 2) {
        return "Easy";
      } else if (this.difficulty_rating === 3) {
        return "Normal";
      } else if (this.difficulty_rating === 4) {
        return "Hard";
      } else if (this.difficulty_rating === 5) {
        return "Hardest";
      }
    },
  },
  methods: {
    setAvatarSource(value) {
      this.avatar_source_url = value;
    },
    setPolicies(value) {
      this.policies = value;
    },
    attributeValues(value) {
      this.attribute_values = value;
    },
    async create_race() {
      try {
        const isDbCreated = await initJsStore(this.race_name);
        if (isDbCreated) {
          console.log("db created");
        } else {
          console.log("db opened");
        }
      } catch (ex) {
        console.error(ex);
        alert(ex.message);
        Global.isIndexedDbSupported = false;
      }
      const raceAdded = this.race_service.addRace({
        name: this.race_name,
        amount_of_primary_competition: this.amount_of_primary_competition,
        randomized_primary_order: this.randomized_primary_order,
        candidate_id: 1,
        difficulty_rating: this.difficulty_rating,
      });
      this.$emit("add-item", raceAdded[0]);
      console.log(typeof this.candidate_first_name);
      console.log(typeof this.candidate_last_name);
      console.log(typeof this.candidate_age);
      console.log(typeof this.previous_position);
      console.log(this.previous_position);
      console.log(typeof this.race);
      console.log(this.race);
      console.log(typeof this.party);
      console.log(this.party);
      console.log(JSON.parse(JSON.stringify(typeof this.religion)));
      console.log(this.religion);
      console.log(typeof this.avatar_source_url);
      let candidateAdded = this.candidate_service.addCandidate({
        user_controlled: true,
        first_name: this.candidate_first_name,
        last_name: this.candidate_last_name,
        age: this.candidate_age,
        state_id: this.state.id,
        previous_position: JSON.parse(JSON.stringify(this.previous_position)),
        race: JSON.parse(JSON.stringify(this.race)),
        party: JSON.parse(JSON.stringify(this.party)),
        nominee: false,
        dropped_out: false,
        religion: JSON.parse(JSON.stringify(this.religion)),
        avatar_url: this.avatar_source_url,
        debating: this.attribute_values[0].value,
        rallying: this.attribute_values[1].value,
        fundraising: this.attribute_values[2].value,
        bad_history: this.attribute_values[3].value,
        charisma: this.attribute_values[4].value,
        consistency: 5,
      });
      this.$emit("add-item", candidateAdded[0]);
      for (let policy of this.policies) {
        let current_policy = this.policy_service.addPolicy(policy);
        this.$emit("add-item", current_policy);
      }
      let i;
      for (i = 0; i < this.amount_of_primary_competition; i++) {
        candidateAdded = this.candidate_service.addCandidate({
          user_controlled: false,
          first_name:
            this.first_names[
              Math.floor(Math.random() * this.first_names.length)
            ],
          last_name:
            this.last_names[Math.floor(Math.random() * this.last_names.length)],
          age: Math.floor(Math.random() * (120 - 35 + 1) + 35),
          previous_position:
            this.positions[Math.floor(Math.random() * this.positions.length)],
          state: this.states[Math.floor(Math.random() * this.states.length)],
          race: this.races[Math.floor(Math.random() * this.races.length)],
          party: this.parties[Math.floor(Math.random() * this.parties.length)],
          nominee: false,
          religion:
            this.religions[Math.floor(Math.random() * this.religions.length)],
          avatar_url: this.$refs.CandidateAvatar.getRandomURL(),
        });
        this.$emit("add-item", candidateAdded[0]);
      }
    },
  },
};
</script>

<style>
.p-float-label {
  margin-top: 5%;
  margin-bottom: 5%;
}
.row {
  display: flex;
}
.column {
  flex: 50%;
}
.p-slider-horizontal {
  width: 14rem;
}

.policy-block {
  display: inline-block;
}

#includes_error {
  color: red
}
</style>