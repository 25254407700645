<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 compass container">
      <img
        src="../assets/political_compass.png"
        alt="Political Compass"
        style="width: 100%"
      />
      <div id="compass-dot" :style="cssProps">•</div>
    </div>
    <div class="p-col-12 p-md-6">
      <h3>Policy Setup</h3>
      <div style="display: inline-block; margin-bottom: 3%">
        <h4>
          Value for All Policies:
          {{ policy_support_word(value_for_all_policies) }}
        </h4>
        <Button label="Set value" @click="setValueForAllPolicies()" />
      </div>
      <Slider v-model="value_for_all_policies" :step="1" :min="1" :max="5" />
      <span
        class="p-float-label"
        v-for="policy in policies"
        :key="policy.policy_name"
      >
        <div style="margin-bottom: 5%">
          <h5 style="display: inline-block; margin-right: 3%">
            {{ policy.policy_name }}:
            {{ policy_support_word(policy.policy_rating) }}
          </h5>
          <Button
            type="button"
            label=""
            icon="pi pi-question-circle"
            v-tooltip="policy.tooltip_help"
          />
        </div>
        <Slider v-model="policy.policy_rating" :step="1" :min="1" :max="5" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoliticalCompass",
  props: {
  },
  data() {
    return {
      policies: [
        {
          policy_name: "Restrict oil drilling",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Implement carbon tax",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Increase taxes on the richest Americans",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Increase taxes on businesses",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Reduce sentences for nonviolent criminals",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Felons should be allowed to vote",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Implement a universal basic income",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Legalize marijuana nationwide",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Make it harder to own a gun",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Make it easier to become a full citizen of the U.S.",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Decrease aid to Israel",
          policy_rating: 3,
          policy_type_compass: "social",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Be tough on China",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Be tough on Russia",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
        {
          policy_name: "Be tough on enemies in the Middle East",
          policy_rating: 3,
          policy_type_compass: "economic",
          tooltip_help: "Bla bla",
        },
      ],
      value_for_all_policies: 3,
    };
  },
  watch: {
    policies() {
      this.$emit("policies", this.policies);
    },
  },
  methods: {
    politicalCompassRating(policy_type) {
      let sum = 0;
      let count = 0;
      for (let policy of this.policies) {
        if (policy.policy_type_compass === policy_type) {
          sum += policy.policy_rating;
          count += 1;
        }
      }
      let value = (20 * sum) / count - 10;
      if (policy_type === "economic") {
        value -= 3
        return value * 0.21;
      } else {
        return value * 0.97;
      }
    },
    setValueForAllPolicies() {
      for (let policy of this.policies) {
        policy.policy_rating = this.value_for_all_policies;
      }
    },
    policy_support_word(rating) {
      if (rating === 1) {
        return "Strongly Support";
      } else if (rating === 2) {
        return "Moderately Support";
      } else if (rating === 3) {
        return "Neutral";
      } else if (rating === 4) {
        return "Moderately Oppose";
      } else if (rating === 5) {
        return "Strongly Oppose";
      }
    },
  },
  computed: {
    cssProps() {
      return {
        "--top-location": this.politicalCompassRating('economic') + "%",
        "--left-location": this.politicalCompassRating('social') + "%",
      };
    },
  },
};
</script>

<style scoped>
#compass-dot {
  position: absolute;
  top: var(--top-location);
  left: var(--left-location);
  color: red;
  font-size: 200%;
}
.container {
  position: relative;
}
</style>