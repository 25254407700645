import { connection } from "./jsstore_con";

export class CandidateService {

    constructor() {

        this.tableName = "Candidates";
    }

    getCandidates() {
        return connection.select({
            from: this.tableName,
        })
    }

    addCandidate(Candidate) {
        return connection.insert({
            into: this.tableName,
            values: [Candidate],
            return: true
        })
    }

    getCandidateById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    removeCandidate(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    updateCandidateById(Candidate) {
        return connection.update({
            in: this.tableName,
            set: {
                user_controlled: Candidate.user_controlled,
                first_name: Candidate.first_name,
                last_name: Candidate.last_name,
                age: Candidate.candidate_age,
                previous_position: Candidate.previous_position,
                race: Candidate.race,
                party: Candidate.party,
                nominee: Candidate.nominee,
                religion: Candidate.religion,
                avatar_url: Candidate.avatar_url,
            },
            where: {
                id: Candidate.id
            }
        })
    }
}