const candidate_presets = [
    {
        name: "Barack Obama 2008",
        candidate_first_name: "Barack",
        candidate_last_name: "Obama",
        previous_position: "Senator",
        state: 14,
        party: "Democrat",
        race: "Black or African American",
        religion: "Evangelical Protestant",
        skin_color: "Black",
        hair_color: "Black",
        hair_style: "Caesar",
        clothing_type: "Blazer Shirt",
        clothing_color: "White",
        eye_type: "Default",
        eyebrow_type: "Default",
        mouth_type: "Default",
        facial_hair_type: "No Facial Hair",
        facial_hair_color: "Black",
        candidate_age: 47,
    },
    {
        name: "Donald Trump 2016",
        candidate_first_name: "Donald",
        candidate_last_name: "Trump",
        previous_position: "Celebrity",
        state: 33,
        party: "Republican",
        race: "White",
        religion: "Evangelical Protestant",
        skin_color: "Tanned",
        hair_color: "Platinum",
        hair_style: "Short Curly",
        clothing_type: "Blazer Shirt",
        clothing_color: "White",
        eye_type: "Default",
        eyebrow_type: "Default",
        mouth_type: "Default",
        facial_hair_type: "No Facial Hair",
        facial_hair_color: "Black",
        candidate_age: 70,
    },
    {
        name: "Bernie Sanders 2016",
        candidate_first_name: "Bernie",
        candidate_last_name: "Sanders",
        previous_position: "Senator",
        state: 46,
        party: "Democrat",
        race: "White",
        religion: "Jewish",
        skin_color: "Light",
        hair_color: "Platinum",
        hair_style: "Sides",
        clothing_type: "Blazer Sweater",
        clothing_color: "White",
        eye_type: "Default",
        eyebrow_type: "Default",
        mouth_type: "Default",
        facial_hair_type: "No Facial Hair",
        facial_hair_color: "Black",
        candidate_age: 75,
    },
    {
        name: "Joe Biden 2020",
        candidate_first_name: "Joe",
        candidate_last_name: "Biden",
        previous_position: "Vice President",
        state: 8,
        party: "Democrat",
        race: "White",
        religion: "Catholic",
        skin_color: "Light",
        hair_color: "Platinum",
        hair_style: "Flat",
        clothing_type: "Blazer Shirt",
        clothing_color: "White",
        eye_type: "Squint",
        eyebrow_type: "Default",
        mouth_type: "Default",
        facial_hair_type: "No Facial Hair",
        facial_hair_color: "Black",
        candidate_age: 78,
    },
    {
        name: "Kanye West 2020",
        candidate_first_name: "Kanye",
        candidate_last_name: "West",
        previous_position: "Celebrity",
        state: 5,
        party: "Independent",
        race: "Black or African American",
        religion: "Evangelical Protestant",
        skin_color: "Black",
        hair_color: "Black",
        hair_style: "Flat",
        clothing_type: "Blazer Shirt",
        clothing_color: "White",
        eye_type: "Default",
        eyebrow_type: "Default Natural",
        mouth_type: "Default",
        facial_hair_type: "Light Beard",
        facial_hair_color: "Black",
        candidate_age: 43,
    },
    {
        name: "Hillary Clinton 2016",
        candidate_first_name: "Hillary",
        candidate_last_name: "Clinton",
        previous_position: "Cabinet Member",
        state: 33,
        party: "Democrat",
        race: "White",
        religion: "Evangelical Protestant",
        skin_color: "Light",
        hair_color: "Golden Blonde",
        hair_style: "Medium",
        clothing_type: "Blazer Shirt",
        clothing_color: "White",
        eye_type: "Default",
        eyebrow_type: "Default",
        mouth_type: "Default",
        facial_hair_type: "No Facial Hair",
        facial_hair_color: "Black",
        candidate_age: 69,
    },
]

export default candidate_presets