<template>
  <h3>Attribute Setup</h3>
  <h4>Available Points: {{ available_points }}</h4>
  <p>{{ attributes }}</p>
  <span
    class="p-float-label"
    v-for="attribute in attributes"
    :key="attribute.name"
  >
    <h5 style="display: inline-block; margin-right: 3%">
      {{ attribute.spelled_name }}: {{ attribute.value }}
    </h5>
    <Slider v-model="attribute.value" :step="1" :min="0" :max="5" />
  </span>
</template>

<script>
export default {
  name: "CandidateAttributes",
  emits: ["attribute_values"],
  props: {},
  data() {
    return {
      attributes: [
        { name: "debating", spelled_name: "Debating", value: 0, old_value: 0 },
        { name: "rallying", spelled_name: "Rallying", value: 0, old_value: 0 },
        {
          name: "fundraising",
          spelled_name: "Fundraising",
          value: 0,
          old_value: 0,
        },
        {
          name: "bad_history",
          spelled_name: "Bad History",
          value: 0,
          old_value: 0,
        },
        { name: "charisma", spelled_name: "Charisma", value: 0, old_value: 0 },
      ],
    };
  },
  watch: {
    attributes: {
      deep: true,
      handler() {
        console.log("hi");
        let attribute;
        if (this.available_points === 0) {
          for (attribute of this.attributes) {
            attribute.old_value = attribute.value;
          }
        } else if (this.available_points < 0) {
          for (attribute of this.attributes) {
            attribute.value = attribute.old_value;
          }
        }
        this.$emit("attribute_values", this.attributes);
      },
    },
  },
  computed: {
    available_points() {
      let sum = 0;
      for (let attribute of this.attributes) {
        sum += attribute.value;
      }
      return 10 - sum;
    },
  },
};
</script>