import { connection } from "./jsstore_con";
import { DATA_TYPE } from "jsstore";

export const getDatabase = (db_name) => {
    const tblRace = {
        name: 'Races',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            amount_of_primary_competition: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            randomized_primary_order: {
                notNull: true,
                dataType: DATA_TYPE.Boolean
            },
            candidate_id: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            difficulty_rating: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
        },
    };
    const tblCandidate = {
        name: 'Candidates',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            user_controlled: {
                notNull: true,
                dataType: DATA_TYPE.Boolean
            },
            first_name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            last_name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            age: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            state_id: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            previous_position: {
                notNull: true,
                dataType: DATA_TYPE.Object,
            },
            race: {
                notNull: true,
                dataType: DATA_TYPE.Object
            },
            party: {
                notNull: true,
                dataType: DATA_TYPE.Object,
            },
            nominee: {
                notNull: true,
                dataType: DATA_TYPE.Boolean,
            },
            dropped_out: {
                notNull: true,
                dataType: DATA_TYPE.Boolean, // true means the candidate is no longer in the race
            },
            religion: {
                notNull: true,
                dataType: DATA_TYPE.Object,
            },
            avatar_url: {
                notNull: true,
                dataType: DATA_TYPE.String,
            },
            available_attribute_points: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            debating: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            rallying: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            fundraising: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            bad_history: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            charisma: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
            consistency: {
                notNull: true,
                dataType: DATA_TYPE.Number,
            },
        },
    };
    const tblPolicies = {
        name: 'Policies',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            candidate_id: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            policy_name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            policy_rating: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            policy_type_compass: {
                notNull: true,
                dataType: DATA_TYPE.String // economic or social, allowing formation of a political compass
            },
            tooltip_help: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
        },
    };
    const tblPolls = {
        name: 'Polls',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            date: {
                notNull: true,
                dataType: DATA_TYPE.DateTime
            },
            poll_type: {
                notNull: true,
                dataType: DATA_TYPE.String // national, head-to-head, state, approval, name recognition
            },
            candidates: {
                notNull: true,
                dataType: DATA_TYPE.Array // array of candidate ids
            },
            values: {
                notNull: true,
                dataType: DATA_TYPE.Array // same length as candidates with values corresponding
            },
        },        
    };
    const tblStates = {
        name: 'States',
        columns: {
            id: {
                primaryKey: true,
                dataType: DATA_TYPE.Number
            },
            name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            population: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            electoral_college_votes: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
        },        
    };
    const tblEvents = {
        name: 'Events',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            name: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            rarity: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            positive_neutral_negative: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
        },        
    };
    const dataBase = {
        name: db_name,
        version: 1,
        tables: [tblRace, tblCandidate]
    };
    return dataBase;
};

// export const getDatabase

export const initJsStore = async (db_name) => {
    const dataBase = getDatabase(db_name);
    return await connection.initDb(dataBase);
};

